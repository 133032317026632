/* Global Styles */
:root {
  --primary-bg: #000;
  --primary-text: #fff;
  --secondary-bg: #fcd535;
  --secondary-text: #181a20;
  --hover-bg: rgba(299, 299, 299, 0.15);
  --active-bg: red;
  --border-color: rgba(44, 44, 44, 1);
}

.navbar-container > div{
  width: 100%;
}
/* Navigation Bar */
.navbar-container {
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: var(--primary-bg);
}

.navbar {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background-color: var(--primary-bg);
  width: 100%
}

.nav-left {
  display: flex;
  align-items: center;
  gap: 2vw;
  font-size: 1.5rem;
  color: var(--secondary-bg);
  font-weight: 600;
  white-space: nowrap;
}

.logo {
  width: 35px;
  aspect-ratio: 1;
}

.nav-title {
  font-family: 'Merriweather', serif;
}

.nav-panel {
  display: flex;
  gap: 1vw;
  flex: 1;
  align-items: center;
  justify-content: center;
  line-height: 2;
}

.nav-panel-element {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  padding: 0.1rem 0.4rem;
  color: var(--primary-text);
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-panel-element:hover {
  background-color: var(--hover-bg);
  transform: scale(1.1);
  border-radius: 8px;
}

.nav-auth {
  display: flex;
  gap: 1vw;
  font-size: 1.2rem;
  font-weight: 500;
  justify-content: end;
}

.nav-auth-sign, .nav-auth-reg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  white-space: nowrap;
}

.nav-auth-sign {
  border: 1.9px solid var(--border-color);
  background-color: var(--primary-bg);
  color: var(--primary-text);
}

.nav-auth-reg {
  border: 1px solid var(--border-color);
  background-color: var(--secondary-bg);
  color: var(--secondary-text);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.material-symbols-outlined {
  color: var(--primary-text);
}

.hidden {
  display: none;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-form, .signin-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

/* Responsive Styles */
@media (max-width: 1000px) {
  .nav-title {
    display: none;
  }
}
@media (max-width: 800px) {
  .navbar {
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 1rem;
    background: linear-gradient(135deg, #000000, #15100b); /* Gradient background */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); /* Shadow for depth */
    border-radius: 15px 15px 0 0; /* Rounded corners */
  }

  .nav-left, .nav-title {
    display: none; /* Hide logo and title */
  }

  .nav-panel {
    justify-content: space-around;
    padding: 0;
    width: 100%;
  }

  .nav-panel-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #fff; /* Bright text color */
    transition: transform 0.2s ease, color 0.2s ease;
  }

  .nav-panel-element:hover {
    transform: scale(1.1); /* Scale up on hover */
    color: #ffeb3b; /* Change color on hover */
  }

  .nav-panel-element-icon {
    font-size: 24px;
    margin-bottom: 5px;
    transition: color 0.2s ease;
  }

  .nav-panel-element-icon:hover {
    color: #ffeb3b; /* Change icon color on hover */
  }
  .noMob{
    display: none;
  }
  .nav-auth {
    
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-auth-sign, .nav-auth-reg {
    font-size: 0.9rem;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    transition: background-color 0.2s ease;
  }

  .nav-auth-sign:hover, .nav-auth-reg:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
.nav-icon {
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(107%) contrast(101%);
}

.changeOver{
  color: orange;
  font-weight: 800;
}
.changeOver:hover{
  color: crimson;
  cursor: pointer;
}