/* ---------------------------------------------------------------------------
   IMPORTS
--------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* ---------------------------------------------------------------------------
   CSS VARIABLES & GLOBAL SETTINGS
--------------------------------------------------------------------------- */
:root {
  /* Colors */
  --primary-bg: #000;
  --primary-text: #fff;
  --secondary-bg: #fcd535;
  --secondary-text: #181a20;
  --hover-bg: rgba(200, 200, 200, 0.15); /* adjusted to valid rgba value */
  --active-bg: red;
  --border-color: rgba(44, 44, 44, 1);
  --primary-color: #007BFF;
  --hover-color: #0056b3;

  /* Sizing & Misc */
  --transition-speed: 0.3s;
  --border-radius: 12px;
}
:root {
  /* Spacing */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 32px;

  /* Typography */
  --font-size-base: clamp(1rem, 1.2vw + 0.8rem, 1.2rem);
  --font-size-heading: clamp(1.5rem, 2vw + 1rem, 2.5rem);
  --font-family-base: Arial, sans-serif;
  --font-family-heading: 'Merriweather', serif;
  --font-weight-normal: 400;
  --font-weight-bold: 700;

  /* Colors */
  --color-primary: #1e1e2f;
  --color-secondary: #34344f;
  --color-accent: #00faff;
  --color-text: #e0e0e0;
  --color-background: #0a0a0a;
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-button-bg: #00faff;
  --color-button-hover: #00aaff;

  /* Gradients */
  --gradient-primary: linear-gradient(135deg, #1e1e2f, #34344f);
  --gradient-dark: linear-gradient(135deg, #1a1a1a, #2a2a2a);

  /* Breakpoints */
  --breakpoint-desktop: 1024px;
  --breakpoint-tablet: 768px;
  --breakpoint-mobile: 480px;
}

/* Global Reset */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Base Typography & Body */

a:focus,
button:focus,
input:focus {
  outline: 2px dashed var(--color-primary);
  outline-offset: 2px;
}
html, body {
  font-family: 'Merriweather', serif;
  padding: 0;
  margin: 0;
  overflow-anchor: none;
  background-color: none;
  color: var(--primary-text);
}

/* ---------------------------------------------------------------------------
   OVERLAY STYLES (e.g., Register/Login modals)
--------------------------------------------------------------------------- */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* darkens background for better focus */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* subtle blur for depth */
  animation: fadeIn var(--transition-speed) ease-in-out;
  z-index: 9999;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to   { opacity: 1; transform: scale(1); }
}

.overlay-content {
  background: #fff;
  padding: 2rem;
  border-radius: var(--border-radius);
  position: relative;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.4s ease-out;
}

@keyframes slideUp {
  from { transform: translateY(30px); opacity: 0; }
  to   { transform: translateY(0); opacity: 1; }
}

.overlay-content h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-text, #333);
}

.overlay-content p {
  margin-top: 1rem;
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

.overlay-content .changeOver {
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 600;
  transition: color 0.2s ease-in-out;
}

.overlay-content .changeOver:hover {
  color: var(--hover-color);
  text-decoration: underline;
}

/* ---------------------------------------------------------------------------
   CLOSE BUTTON (Stylish & Attractive)
--------------------------------------------------------------------------- */
.close-btn {
  position: absolute;
  top: 0;
  right: 20px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

/* Create the "X" using pseudo-elements */
.close-btn::before,
.close-btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #333;
  transition: background-color 0.3s ease;
}

/* Rotate the pseudo-elements to form an "X" */
.close-btn::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Hover effects for a more interactive feel */
.close-btn:hover {
  background: #f0f0f0;
  transform: scale(1.1);
}

.close-btn:hover::before,
.close-btn:hover::after {
  background-color: #ff0000;
}

/* ---------------------------------------------------------------------------
   FORMS & INPUTS
--------------------------------------------------------------------------- */
form,
.form-step {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input {
  border: 1px solid #ccc; /* Subtle full border */
  border-radius: 4px;     /* Rounded corners */
  padding: 8px 12px;      /* Comfortable padding */
  margin: 5px 0.5rem 5px 0; /* Consistent spacing */
  font-size: 16px;        /* Readable text size */
  outline: none;          /* Remove default focus outline */
  transition: border-color 0.3s ease; /* Smooth hover/focus effect */
}
input:focus {
  border-color: #007bff; /* Blue focus state */
}
input:hover {
  border-color: #999; /* Slightly darker on hover */
}

/* ---------------------------------------------------------------------------
   BUTTON STYLES
--------------------------------------------------------------------------- */
button {
  border: none;
  outline: none;
  border-radius: var(--border-radius);
  cursor: pointer;
}

/* Default button style */
.btn {
  position: relative;
  width: 100%;
  height: 40px;
  background: linear-gradient(to right, #1E2022, #52616B);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  text-align: center;
  color: #F0F5F9;
  font-weight: 500;
}

/* Google-style button (or alternate style) */
.btn-gg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  width: 100%;
  height: 40px;
  background: linear-gradient(to right, #FBF6E2, #E68369);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

/* ---------------------------------------------------------------------------
   ICON & SVG UTILS
--------------------------------------------------------------------------- */
svg {
  height: 60%;
}

.iconic {
  height: 30px;
}

/* ---------------------------------------------------------------------------
   OTHER UTILITIES
--------------------------------------------------------------------------- */
.regInput {
  width: 100%;
  padding: 0.2rem;
}

/* A sticky container for navbars */
.navbar-container {
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Loader Animation */
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: linear-gradient(
    90deg,
    #000 50%,
    transparent 0
  ) right/200% 100%;
  animation: l21 2s infinite linear;
}

.loader::before {
  content: "Loading...";
  color: transparent;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg, #fff 50%, #000 0);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l21 {
  100% { background-position: left; }
}
/* Loader.css */

/* Full-screen centered container */
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff; /* Background color (can be transparent or themed) */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it sits above your content */
}

/* Ripple effect container */
.ripple-loader {
  position: relative;
  width: 80px;
  height: 80px;
}

/* Each circle in the ripple animation */
.ripple-loader div {
  position: absolute;
  border: 4px solid #3498db; /* Change to your preferred accent color */
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
}

/* Delay for the second circle */
.ripple-loader div:nth-child(2) {
  animation-delay: -0.75s;
}

/* Keyframes for the ripple animation */
@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/* Define CSS custom properties for colors, spacing, and other design tokens */
:root {
  /* Colors */
  --background: 0, 0%, 100%;
  --foreground: 222, 47%, 11%;
  
  --card: 0, 0%, 100%;
  --card-foreground: 222, 47%, 11%;
  
  --popover: 0, 0%, 100%;
  --popover-foreground: 222, 47%, 11%;
  
  --primary: 240, 100%, 50%;
  --primary-foreground: 0, 0%, 100%;
  
  --secondary: 0, 0%, 90%;
  --secondary-foreground: 222, 47%, 11%;
  
  --muted: 210, 16%, 93%;
  --muted-foreground: 215, 20%, 51%;
  
  --accent: 340, 82%, 52%;
  --accent-foreground: 0, 0%, 100%;
  
  --destructive: 0, 84%, 60%;
  --destructive-foreground: 0, 0%, 100%;
  
  --border: 210, 16%, 93%;
  --input: 210, 16%, 93%;
  --ring: 215, 20%, 51%;
  
  --chart-1: 0, 0%, 50%;
  --chart-2: 0, 0%, 60%;
  --chart-3: 0, 0%, 70%;
  --chart-4: 0, 0%, 80%;
  --chart-5: 0, 0%, 90%;
  
  --sidebar-background: 0, 0%, 100%;
  --sidebar-foreground: 222, 47%, 11%;
  --sidebar-primary: 240, 100%, 50%;
  --sidebar-primary-foreground: 0, 0%, 100%;
  --sidebar-accent: 340, 82%, 52%;
  --sidebar-accent-foreground: 0, 0%, 100%;
  --sidebar-border: 210, 16%, 93%;
  --sidebar-ring: 215, 20%, 51%;

  /* Radius */
  --radius: 0.5rem;
}

/* Font Family */
body {
  font-family: "Merriweather", serif;
}

/* Border Radius Utilities */
.rounded-lg {
  border-radius: var(--radius);
}
.rounded-md {
  border-radius: calc(var(--radius) - 2px);
}
.rounded-sm {
  border-radius: calc(var(--radius) - 4px);
}

/* Keyframes for Accordion Animations */
@keyframes accordion-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

/* Animation Classes */
.accordion-down {
  animation: accordion-down 0.2s ease-out;
}

.accordion-up {
  animation: accordion-up 0.2s ease-out;
}

/* Example utility classes for text and background colors using the custom properties */
.bg-background {
  background-color: hsl(var(--background));
}

.text-foreground {
  color: hsl(var(--foreground));
}

/* Card color (using custom properties from your config) */
.bg-card {
  background-color: hsl(var(--card));
}

.text-card-foreground {
  color: hsl(var(--card-foreground));
}

/* Primary colors */
.bg-primary {
  background-color: hsl(var(--primary));
}
.text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

/* Secondary colors */
.bg-secondary {
  background-color: hsl(var(--secondary));
}
.text-secondary-foreground {
  color: hsl(var(--secondary-foreground));
}

/* Muted */
.bg-muted {
  background-color: hsl(var(--muted));
}
.text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

/* Accent */
.bg-accent {
  background-color: hsl(var(--accent));
}
.text-accent-foreground {
  color: hsl(var(--accent-foreground));
}

/* Destructive */
.bg-destructive {
  background-color: hsl(var(--destructive));
}
.text-destructive-foreground {
  color: hsl(var(--destructive-foreground));
}

/* Border and ring utilities */
.border {
  border-color: hsl(var(--border));
}
.ring {
  box-shadow: 0 0 0 2px hsl(var(--ring));
}
